import React, { useEffect, useState } from 'react'
import {
  changeRequestStatus,
  meetingsUnloaded,
  selectAllMeetings,
  selectRequestStatus,
  selectTotalCount,
} from '../../../../reducers/meetings/meeting-list'
import { getAllMeetings } from './slice'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import Loader from '../../../common/loader/Loader'
import GroupedMeetingList from './GroupedMeetingList'
import NoMeetingsMessage from './NoMeetingsMessage'
import { ITEMS_PER_PAGE } from '../../../../constants'
import Pagination from '../../../common/Pagination'

export default function Meetings() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const meetings = useSelector(selectAllMeetings)
  const meetingsTotal = useSelector(selectTotalCount)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE)

  const [filteredMeeting, setFilteredMeeting] = useState([])

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchMeetings = dispatch(getAllMeetings({ page: currentPage, pageSize: itemsPerPage }))

    return () => {
      dispatch(meetingsUnloaded())
      fetchMeetings.abort()
    }
  }, [])

  useEffect(() => {
    let filtered = []
    if (meetings && meetings.length > 0) {
      filtered = [...meetings]
      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))

      setFilteredMeeting(filtered)
    }
  }, [meetings])

  const handlePageChange = (page) => {
    setCurrentPage(page)
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(getAllMeetings({ page: page, pageSize: itemsPerPage }))
  }

  return (
    <div>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <>
          <div className="py-8 sm:px-6 lg:flex-auto lg:px-0">
            {meetings && requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
            {(!meetings || meetings.length === 0) && requestStatus !== REQUEST_STATUSES.PENDING && (
              <NoMeetingsMessage />
            )}
            {meetings && filteredMeeting.length > 0 && <GroupedMeetingList meetings={filteredMeeting} />}
          </div>
          {meetingsTotal > 0 && (
            <Pagination
              defaultCurrentPage={currentPage}
              totalItems={meetingsTotal}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
    </div>
  )
}
