import { createSlice } from '@reduxjs/toolkit'
import { updateWorkspaceGeneralSettings } from '../../../components/workspace/settings/general/slice'
import { failed, resetErrors, resetStatus } from '../../reducer-helper'

const initialState = {
  settings: {
    name: '',
    dataExpirationPeriod: null,
  },
  formLoaded: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'workspaceGeneralSettingsForm',
  initialState,
  reducers: {
    changeName: (state, action) => {
      state.settings.name = action.payload.name
    },
    changeExpirationPeriod: (state, action) => {
      state.settings.dataExpirationPeriod = action.payload.dataExpirationPeriod
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    setSettings: (state, action) => {
      state.settings.name = action.payload.name
      state.settings.dataExpirationPeriod = action.payload.dataExpirationPeriod

      state.formLoaded = true
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(updateWorkspaceGeneralSettings.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(updateWorkspaceGeneralSettings.rejected, failed)
  },
})

const selectSlice = (state) => state.workspaceGeneralSettingsForm
export const selectSettings = (state) => selectSlice(state).settings
export const isSettingsFormLoaded = (state) => selectSlice(state).formLoaded
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeName, changeExpirationPeriod, changePassword, setSettings, resetForm, changeRequestStatus } =
  actions

export default reducer
