import { requests } from './agent'

export const WorkspaceAgent = {
  getAllWorkspaces: () => {
    return requests.get('/1_0/workspaces/list')
  },

  updateGeneralSettings: (name, dataExpirationPeriod) => {
    return requests.post('/1_0/workspaces/settings/update', { name, dataExpirationPeriod })
  },

  addImage: (file) => {
    const formData = new FormData()
    formData.append('file', file)

    return requests.post(`/1_0/workspaces/settings/image/add`, formData, { 'Content-Type': 'multipart/form-data' })
  },

  removeImage: () => {
    return requests.post('/1_0/workspaces/settings/image/remove')
  },

  updateSecuritySettings: (allowPrivateMeetings) => {
    return requests.post('/1_0/workspaces/settings/security/update', { allowPrivateMeetings })
  },

  getAllUsers: () => {
    return requests.get('/1_0/workspaces/users/list')
  },

  inviteUsers: (emails, groups) => {
    return requests.post('/1_0/workspaces/users/invite', { emails, groups })
  },

  updateRole: (uuid, role) => {
    return requests.post(`/1_0/workspaces/users/${uuid}/update-role`, { uuid, role })
  },

  removeUser: (uuid) => {
    return requests.post(`/1_0/workspaces/users/${uuid}/remove`)
  },

  createWorkspaces: (name, plan, paymentMethodId) => {
    return requests.post('/1_0/workspaces/create', {
      name,
      plan,
      paymentMethodId,
    })
  },

  switchWorkspace: (workspaceUuid) => {
    return requests.post(`/1_0/workspaces/${workspaceUuid}/switch`)
  },

  checkInvitation: (token) => {
    return requests.post(`/1_0/workspaces/invitations/${token}/check`)
  },

  acceptInvitation: (workspaceUuid) => {
    return requests.post(`/1_0/workspaces/${workspaceUuid}/invitations/accept`)
  },

  acceptInvitationWithSignUp: (token, fullName, password) => {
    return requests.post(`/1_0/workspaces/invitations/${token}/accept-with-sign-up`, { fullName, password })
  },

  acceptInvitationWithSignIn: (token, email, password) => {
    return requests.post(`/1_0/workspaces/invitations/${token}/accept-with-sign-in`, { email, password })
  },

  getAllGroups: () => {
    return requests.get('/1_0/workspaces/groups/list')
  },

  getGroupDetails: (uuid) => {
    return requests.get(`/1_0/workspaces/groups/${uuid}`)
  },

  createGroup: (name, openMeetings, members) => {
    return requests.post('/1_0/workspaces/groups/create', { name, openMeetings, members })
  },

  updateGroup: (uuid, name, openMeetings) => {
    return requests.post(`/1_0/workspaces/groups/${uuid}/update`, { name, openMeetings })
  },

  addGroupMember: (groupUuid, workspaceUserUuid) => {
    return requests.post(`/1_0/workspaces/groups/${groupUuid}/members/add`, { workspaceUserUuid })
  },

  removeGroupMember: (groupUuid, memberUuid) => {
    return requests.post(`/1_0/workspaces/groups/${groupUuid}/members/${memberUuid}/remove`)
  },

  updateGroupMemberRole: (groupUuid, memberUuid, isManager) => {
    return requests.post(`/1_0/workspaces/groups/${groupUuid}/members/${memberUuid}/role-update`, { isManager })
  },

  getGroupUsageData: (groupUuid, year, month) => {
    return requests.get(`/1_0/workspaces/groups/${groupUuid}/usage/${year}/${month}`)
  },

  getUsageData: (year, month) => {
    return requests.get(`/1_0/workspaces/usage/${year}/${month}`)
  },

  upgrade: (paymentMethodId) => {
    return requests.post(`/1_0/workspaces/upgrade`, { paymentMethodId })
  },

  cancelSubscription: () => {
    return requests.post(`/1_0/workspaces/cancel-subscription`)
  },

  revokeCancellation: () => {
    return requests.post(`/1_0/workspaces/revoke-subscription-cancellation`)
  },

  getSubscription: () => {
    return requests.get(`/1_0/workspaces/subscription`)
  },

  downgrade: () => {
    return requests.post(`/1_0/workspaces/downgrade`)
  },

  getAllApiKeys: () => {
    return requests.get(`/1_0/workspaces/api-keys`)
  },

  createApiKey: (name) => {
    return requests.post(`/1_0/workspaces/api-keys/create`, { name })
  },

  resetApiKey: (apiKeyUuid) => {
    return requests.post(`/1_0/workspaces/api-keys/${apiKeyUuid}/reset`)
  },

  deleteApiKey: (apiKeyUuid) => {
    return requests.post(`/1_0/workspaces/api-keys/${apiKeyUuid}/delete`)
  },
}
