import React, { useEffect, useState } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loader from '../../common/loader/Loader'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import GroupedRecordList from './GroupedRecordList'
import NoRecordsMessage from './NoRecordsMessage'
import { setShowPopupState } from '../../../reducers/records/upload-record-form'
import {
  recordsUnloaded,
  selectAllRecords,
  changeRequestStatus,
  selectRequestStatus,
  selectTotalCount,
} from '../../../reducers/records/record-list'
import { getAllRecords } from './slice'
import { ITEMS_PER_PAGE } from '../../../constants'
import Pagination from '../../common/Pagination'

export default function RecordList() {
  const dispatch = useDispatch()

  const [searchQuery, setSearchQuery] = useState('')

  const requestStatus = useSelector(selectRequestStatus)
  const records = useSelector(selectAllRecords)
  const meetingsTotal = useSelector(selectTotalCount)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE)

  const [filteredRecords, setFilteredRecords] = useState([])

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetch = dispatch(getAllRecords({ page: currentPage, pageSize: itemsPerPage }))

    return () => {
      dispatch(recordsUnloaded())
      fetch.abort()
    }
  }, [])

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  useEffect(() => {
    let filtered = []
    if (records && records.length > 0) {
      filtered = [...records]
      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))

      if (searchQuery) {
        filtered = filtered.filter((record) => record.name.toLowerCase().search(searchQuery.toLowerCase()) !== -1)
      }

      setFilteredRecords(filtered)
    }
  }, [records, searchQuery])

  const showFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: true }))
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(getAllRecords({ page: page, pageSize: itemsPerPage }))
  }

  return (
    <MainLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <div className="bg-whiteX">
          <header>
            <div className="mx-auto h-16 flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <button
                type="button"
                className="mr-4 p-2.5 text-black lg:hidden"
                onClick={() => changeShowNavigationSidebarProcess(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <h1 className="text-base font-semibold leading-7 text-gray-900">Records and Transcriptions</h1>
              {filteredRecords && filteredRecords.length > 0 && (
                <button
                  onClick={showFormHandler}
                  className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Upload records
                </button>
              )}
            </div>
          </header>

          <div className="overflow-hidden border-t border-gray-100">
            {((records && filteredRecords.length > 0) || searchQuery !== '') && (
              <div className="flex h-16 shrink-0 items-center gap-x-6 border-b border-black/5 bg-white sm:px-6 lg:px-8">
                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                  <form className="flex flex-1" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
                        aria-hidden="true"
                      />
                      <input
                        id="search-field"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-gray-700 focus:ring-0 sm:text-sm"
                        placeholder="Search records..."
                        type="search"
                        name="search"
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div className="mx-auto">
              <div className="mx-auto lg:mx-0 lg:max-w-none">
                {records && requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
                {(!records || records.length === 0) && requestStatus !== REQUEST_STATUSES.PENDING && (
                  <NoRecordsMessage />
                )}
                {records && filteredRecords.length > 0 && <GroupedRecordList records={filteredRecords} />}
              </div>
            </div>
          </div>
          {records && records.length > 0 && (
            <Pagination
              defaultCurrentPage={currentPage}
              totalItems={meetingsTotal}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </MainLayout>
  )
}
