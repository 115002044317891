import React, { memo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSettings,
  selectError,
  selectRequestStatus,
  changeName,
  resetForm,
  setSettings,
  changeRequestStatus,
  changeExpirationPeriod,
} from '../../../../reducers/workspace/settings/general-settings-form'
import { selectWorkspace } from '../../../../reducers/workspace/workspace'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { addImage, removeImage, updateWorkspaceGeneralSettings } from './slice'
import { setShowPopupState as setShowCancelSubscriptionPopupState } from '../../../../reducers/workspace/settings/cancel-subscription-form'
import { setShowPopupState as setShowRevokeCancellationPopupState } from '../../../../reducers/workspace/settings/revoke-subscription-cancellation-form'
import { selectSubscription } from '../../../../reducers/workspace/subscription'
import { CameraIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CND_URL } from '../../../../configs'

function WorkspaceGeneralSettings() {
  const dispatch = useDispatch()
  const inputRef = useRef(null)

  const formData = useSelector(selectSettings)
  const workspace = useSelector(selectWorkspace)
  const subscription = useSelector(selectSubscription)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeExpirationProcess = (event) => {
    event.preventDefault()
    dispatch(changeExpirationPeriod({ dataExpirationPeriod: event.target.value }))
  }

  useEffect(() => {
    dispatch(setSettings({ name: workspace.name, dataExpirationPeriod: workspace.dataExpirationPeriod }))
  }, [workspace])

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateWorkspaceGeneralSettings({
        name: formData.name,
        dataExpirationPeriod: formData.dataExpirationPeriod || null,
      }),
    )
  }

  const showCancelSubscriptionFormHandler = () => {
    dispatch(setShowCancelSubscriptionPopupState({ showPopup: true }))
  }

  const showRevokeCancellationFormHandler = () => {
    dispatch(setShowRevokeCancellationPopupState({ showPopup: true }))
  }

  const showSaveButton = () => {
    return formData.name !== workspace.name || formData.dataExpirationPeriod !== workspace.dataExpirationPeriod
  }

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  const removeImageAction = (event) => {
    event.preventDefault()
    dispatch(removeImage({}))
  }

  const addImageAction = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    dispatch(addImage({ file }))
  }

  return (
    <>
      <div className="mx-auto space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <div className={'flex flex-col pb-6 border-b border-gray-200'}>
            <h2 className="text-base font-semibold leading-7 text-gray-900">General</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">Workspace general settings</p>
          </div>
          <form onSubmit={saveSettings} className="mt-6 max-w-4xl space-y-6 divide-y divide-gray-100 text-sm leading-6">
            <div className="sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Workspace name</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <input
                  id="workspace-name"
                  name="workspace-name"
                  type="string"
                  value={formData.name}
                  onChange={changeNameProcess}
                  placeholder="Workspace name"
                  autoComplete="workspace-name"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Workspace Image</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                {workspace.image ? (
                  <div>
                    <div
                      className={
                        'w-24 h-24 flex items-center justify-center rounded-full border-2 border-gray-200 relative'
                      }
                    >
                      <button
                        type="button"
                        onClick={(event) => removeImageAction(event)}
                        className={`absolute z-10 bg-red-700 text-white p-1 rounded-3xl -top-1 -right-1 hover:bg-red-500 transition-all`}
                      >
                        <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                      </button>
                      <img className={'w-16 h-16'} src={`${CND_URL}/${workspace.image}`} alt="" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        inputRef.current?.click()
                      }}
                      className={`flex flex-col w-24 h-24 items-center justify-center rounded-full bg-gray-50 border-gray-100 border-1 px-3 py-1.5 text-xs hover:bg-indigo-100 transition-all`}
                    >
                      <CameraIcon className={'w-6 h-6 mb-1'} />
                      <div>Add image</div>
                    </button>
                    <input
                      onChange={addImageAction}
                      type="file"
                      ref={inputRef}
                      className="hidden w-full text-black text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-black rounded-md"
                    />
                  </div>
                )}
              </dd>
            </div>
            <div className="sm:flex pt-6">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Data expiration period (in days)
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <input
                  id="workspace-expiration"
                  name="workspace-expiration"
                  type="number"
                  value={formData.dataExpirationPeriod}
                  onChange={changeExpirationProcess}
                  placeholder="Data expiration period (in days)"
                  autoComplete="workspace-expiration"
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </dd>
            </div>
            {showSaveButton() && (
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-500 sm:w-64 sm:flex-none sm:pr-6"></dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <button
                    type="submit"
                    className={`flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    <span>Save changes</span>
                  </button>
                </dd>
              </div>
            )}

            {/*{!subscription?.cancelAt && (*/}
            {/*  <div className="pt-24 sm:flex">*/}
            {/*    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">*/}
            {/*      Cancel workspace subscription*/}
            {/*    </dt>*/}
            {/*    <dd className={'flex flex-col'}>*/}
            {/*      <button*/}
            {/*        type={'button'}*/}
            {/*        onClick={showCancelSubscriptionFormHandler}*/}
            {/*        className={`mr-auto flex items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-50 ring-2 ring-indigo-500`}*/}
            {/*      >*/}
            {/*        Cancel subscription*/}
            {/*      </button>*/}

            {/*      <div className={'text-xs mt-2 text-gray-400'}>*/}
            {/*        Your workspace will be downgraded to the Personal plan. You will lose all Team plan benefits.*/}
            {/*      </div>*/}
            {/*    </dd>*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*{subscription?.cancelAt && (*/}
            {/*  <div className="pt-24 sm:flex">*/}
            {/*    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">*/}
            {/*      Cancel workspace subscription*/}
            {/*    </dt>*/}
            {/*    <dd className={'flex flex-col'}>*/}
            {/*      <div className={'text-sm mb-4 text-gray-700'}>*/}
            {/*        Your workspace subscription was already canceled and will be downgraded in{' '}*/}
            {/*        <span className={'font-semibold'}>*/}
            {/*          {format(new Date(subscription.cancelAt * 1000), 'MMMM do, yyyy')}*/}
            {/*        </span>*/}
            {/*      </div>*/}
            {/*      <button*/}
            {/*        type={'button'}*/}
            {/*        onClick={showRevokeCancellationFormHandler}*/}
            {/*        className={`mr-auto flex items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-50 ring-2 ring-indigo-500`}*/}
            {/*      >*/}
            {/*        Revoke cancellation*/}
            {/*      </button>*/}
            {/*    </dd>*/}
            {/*  </div>*/}
            {/*)}*/}

            <div className="pt-24 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Delete workspace</dt>
              <dd className={'flex flex-col'}>
                <button
                  className={`mr-auto flex items-center justify-center rounded-md bg-white px-3 py-1 text-red-800 hover:text-red-600 hover:bg-gray-50 ring-2 ring-red-800`}
                >
                  Delete workspace
                </button>

                <div className={'text-xs mt-2 text-gray-400'}>
                  Please be careful, by clicking this button you will delete this workspace and all meetings related to
                  it.
                </div>
              </dd>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default memo(WorkspaceGeneralSettings)
